import "./sidebar.scss";
import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";

function SideBar() {
  const { currentuser, logout } = useAuthContext();
  const [hoveredButton, setHoveredButton] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 991);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = async () => {
    await logout();
  };

  return (
    <aside className={`sidebar`}>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous"></link>
      <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap" rel="stylesheet"></link>
      <div className="nav-top">
        {!isMobile && (
          <a href="/" className="logo-link w-inline-block">
            <img src="https://brooksfieldserviceltd.com/newassets/images/site-images/bfslogo-removebg-500.webp" loading="lazy" width="210" alt="Logo" />
          </a>
        )}
        <div className="nav-separator"></div>
        <div className="nav-menu">
          <ul className="center">
            <NavLink
              to={"/"}
              onMouseEnter={() => setHoveredButton('home')}
              onMouseLeave={() => setHoveredButton(null)}
              className={({ isActive }) => `${isActive || hoveredButton === 'home' ? "activeClassName" : ""}`}
            >
              <li>
                <div className="icon">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1692_35)">
                      <path d="M6.56113 0.805664H0.805664V6.56113H6.56113V0.805664Z"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                      <path d="M15.1949 0.805664H9.43945V6.56113H15.1949V0.805664Z"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                      <path d="M6.56113 9.43945H0.805664V15.1949H6.56113V9.43945Z"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                      <path d="M15.1949 9.43945H9.43945V15.1949H15.1949V9.43945Z"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1692_35">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                {!isMobile && <span className="nav-link_text">Home</span>}
              </li>
            </NavLink>
            <NavLink
              to={"/assets"}
              onMouseEnter={() => setHoveredButton('assets')}
              onMouseLeave={() => setHoveredButton(null)}
              className={({ isActive }) => `${isActive || hoveredButton === 'assets' ? "activeClassName" : ""}`}
            >
              <li>
                <div className="icon">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1692_52)">
                      <path d="M1.07715 11.9648L8.00023 16.0033L14.9233 11.9648" stroke="currentColor"
                        strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.07715 8.50391L8.00023 12.5424L14.9233 8.50391" stroke="currentColor"
                        strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path
                        d="M1.07715 5.04237L8.00023 9.08083L14.9233 5.04237L8.00023 1.00391L1.07715 5.04237Z"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1692_52">
                        <rect width="16" height="16.7273" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                {!isMobile && <span className="nav-link_text">Assets</span>}
              </li>
            </NavLink> <NavLink
              to={"/dropbox"}
              onMouseEnter={() => setHoveredButton('dropbox')}
              onMouseLeave={() => setHoveredButton(null)}
              className={({ isActive }) => `${isActive || hoveredButton === 'dropbox' ? "activeClassName" : ""}`}
            >
              <li>
                <div className="icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0" />
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H12M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19M19 9V12M17 19H21M19 17V21"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>

                </div>
                {!isMobile && <span className="nav-link_text">Dropbox</span>}
              </li>
            </NavLink>
            <NavLink
              to={"/users"}
              onMouseEnter={() => setHoveredButton('users')}
              onMouseLeave={() => setHoveredButton(null)}
              className={({ isActive }) => `${isActive || hoveredButton === 'users' ? "activeClassName" : ""}`}
            >
              <li>
                <div className="icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0" />
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M19 15C21.2091 15 23 16.7909 23 19V21H21M16 10.874C17.7252 10.4299 19 8.86383 19 6.99999C19 5.13615 17.7252 3.57005 16 3.12601M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7ZM5 15H13C15.2091 15 17 16.7909 17 19V21H1V19C1 16.7909 2.79086 15 5 15Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>

                </div>
                {!isMobile && <span className="nav-link_text">Users</span>}
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
      <div className="nav-bot">
        {currentuser && (
          <ul className="center">
            <NavLink
              to={"/login"}
              onClick={handleLogout}
              onMouseEnter={() => setHoveredButton('logout')}
              onMouseLeave={() => setHoveredButton(null)}
              className={({ isActive }) => `${isActive || hoveredButton === 'logout' ? "activeClassName" : ""}`}
            >
              <li>
                <div className="icon w-embed">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1692_84)">
                      <path fillRule="evenodd" clipRule="evenodd"
                        d="M0.0078125 0.0078125H8.49266V1.21993H1.21993V14.5533H8.49266V15.7654H0.0078125V0.0078125ZM11.9516 3.82169L15.9996 7.8698L11.9683 12.237L11.0776 11.415L13.7751 8.49266H3.64418V7.28054H13.6962L11.0944 4.67878L11.9516 3.82169Z"
                        fill="currentColor" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1692_84">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                {!isMobile && <span className="nav-link_text">Logout</span>}
              </li>
            </NavLink>
          </ul>
        )}
      </div>
    </aside>
  );
}

export default SideBar;
