import React, { useEffect, useState } from "react";
import "./card.scss";
import { collection, getDocs } from "firebase/firestore";
import { fireStore } from "../../firebase-config";
import { USERS, assetS } from "../../App";
import { useAuthContext } from "../../context/AuthContext";

function Card({ type }) {
  const { seterrMsg, setError } = useAuthContext();
  const [count, setCount] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!type) return;
        const dataQuery = collection(fireStore, type);
        const data = await getDocs(dataQuery);
        setCount(data.docs.length);
        setError(false);
        seterrMsg("");
      } catch (err) {
        setError(true);
        seterrMsg(err.message);
      }
    };
    getData();
  }, [type, setError, seterrMsg]);

  return (
    <article className="card">
 
      <div className="group-hero_text light">
        {type === USERS && `Currently, there are ${count} users.`}
        {type === assetS && `and ${count} images uploaded.`}
      </div>
    </article>
  );
}

export default Card;
