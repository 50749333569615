
export const userSource = [
	{
		type: "text",
		required: true,
		label: "Name",
		id: "username",
		placeholder: "John Doe"
	},
	{
		type: "email",
		required: true,
		label: "Email",
		id: "email",
		placeholder: "Example@Example.com"
	},
	{
		type: "password",
		required: true,
		label: "Password",
		id: "password",
		placeholder: ""
	}
]

export const assetsSource = [
	{
		type: "text",
		required: true,
		label: "Title",
		id: "title",
		placeholder: "Example Text"
	}
]

export const dropboxSource = [
	{
		type: "text",
		required: true,
		label: "Title",
		id: "title",
		placeholder: "Example Text"
	}
]
