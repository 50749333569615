import { sendPasswordResetEmail } from "firebase/auth";
import { firebaseAuth } from "../firebase-config";


export const userColumns = [
  {
    field: "username",
    headerName: "Username",
    width: 130,
    renderCell: function (params) {
      return (
        <div className="cellWithImage">
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 130,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      const status = ["Loading", "Active", "Inactive"][generateStatus()]
      return <div className={`cellWithStatus ${status}`}>
        {status}
      </div >
    }
  }, { field: "id", headerName: "Account ID", width: 300 },
    
    {
      field: "resetPassword",
      headerName: "Reset Password",
      width: 150,
      renderCell: (params) => {
        return (
          <button
            className="resetPasswordButton"
            onClick={() => handlePasswordReset(params.row.email)} 
          >
            Reset Password
          </button>
        );
      },
    },
  ];
  
  const generateStatus = () => Math.round(Math.random() * 2);
  
  const handlePasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(firebaseAuth, email);
      alert(`Password reset email sent to ${email}`);
    } catch (error) {
      alert("Failed to send password reset email. Please try again.");
      console.error(error);
    }
  };
export const assetsColumns = [
  {
    width: 130,
    headerName: "Name",
    field: "title",
  },
  {
    field: "image",
    headerName: "Image",
    width: 130,
    renderCell: function (params) {
      return (
        <div className="cellWithImage">
          <img src={params.row.image} className="cellImg" alt="Avatar" />
        </div>
      );
    },
  },
];


export const dropboxColumns = [
  {
    width: 130,
    headerName: "Name",
    field: "title",
  },
  {
    field: "image",
    headerName: "Image",
    width: 130,
    renderCell: function (params) {
      return (
        <div className="cellWithImage">
          <img src={params.row.image} className="cellImg" alt="Avatar" />
        </div>
      );
    },
  },
];

