import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import HomePage from "./pages/home/Home";
import SideBar from "./components/sidebar/SideBar";
import NewItem from "./components/new/NewItem";
import DataTable from "./components/datatable/DataTable";

import { dropboxSource, assetsSource, userSource } from "./data/formSourceData";
import LoginForm from "./pages/login/LoginForm";
import RequiredAuth from "./components/auth/RequiredAuth";
import { useAuthContext } from "./context/AuthContext";

const Preloader = ({ fadeOut }) => {
  return (
    <div id="preloader" className={fadeOut ? "fade-out" : ""}>
      <div className="loader"></div>
    </div>
  );
};

export const assetS = "assets";
export const USERS = "users";
export const Dropbox = "dropbox";

function App() {
  const { currentuser } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const location = useLocation();

  useEffect(() => {

    setLoading(true);
    setFadeOut(false);

    const loadApp = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }, 400);

    return () => clearTimeout(loadApp);
  }, [location]);

  if (loading) {
    return <Preloader fadeOut={fadeOut} />;
  }

  return (
    <>
      {currentuser && <SideBar />}
      <div className={`contentWrap`}>
        <Routes>
          <Route
            path="/login"
            element={currentuser ? <Navigate to="/login" replace /> : <LoginForm />}
          />

          <Route path="/" element={<RequiredAuth />}>
            <Route index element={<HomePage />} />
            <Route path={assetS}>
              <Route index element={<DataTable path={assetS} />} />
              <Route
                path="new"
                element={
                  <NewItem
                    path={assetS}
                    dataSource={assetsSource}
                    title={"Add new asset"}
                  />
                }
              />
            </Route><Route path={USERS}>
              <Route index element={<DataTable />} />
              <Route
                path="new"
                element={
                  <NewItem
                    dataSource={userSource}
                    title={"Add new User"}
                    path={USERS}
                  />
                }
              />
            </Route><Route path={Dropbox}>
              <Route index element={<DataTable />} />
              <Route
                path="new"
                element={
                  <NewItem
                    dataSource={dropboxSource}
                    title={"Add PUBLIC Files"}
                    path={Dropbox}
                  />
                }
              />
            </Route>
          </Route>

          <Route path="*" element={<h1>Page Not Found</h1>} />
        </Routes>
      </div>
    </>
  );
}

export default App;
