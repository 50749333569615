import { assetS, USERS } from "../../App";
import Card from "../../components/card/Card";
import "./home.scss";

function Home() {
  return (
    <div className="home"><div data-w-id="42a73d41-4912-da65-0aa9-4571f4b96690" className="navigation">
      <h1>Home</h1>
      <div className="navigation-right">
        <div className="user-wrap">
          <div className="user">
            <div className="user-info">
              <div className="user-name">Logged In</div>
            </div>
            <img src="https://brooksfieldserviceltd.com/assets/images/site-images/favicon.png" loading="lazy" alt="User Icon" className="user-icon"></img>
          </div>
        </div>
      </div>
    </div>
      <section>
      <Card type={USERS} />
        <Card type={assetS} />
      </section>
    </div>
  );
}

export default Home;
